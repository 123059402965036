import sortBy from 'lodash/sortBy';
import { useRef, useState } from 'react';
// @ts-ignore
import { Controller, EffectCoverflow, Lazy } from 'swiper';
import 'swiper/css/bundle'; // eslint-disable-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react'; // eslint-disable-line import/no-unresolved
import type { SwiperRef } from 'swiper/react';

import { Button } from '../ui/button';
import { NoScriptPolyfill } from '@/utils/noScriptPolyfill';

import './index.scss';

const x2SrcSets = require.context(
  '!!webpack-image-srcset-loader?&esModule=false&quality=100&sizes[]=1x&sizes[]=2x!file-loader?outputPath=static&name=[path][name].csswiper.[contenthash:6].[ext]&context=src&esModule=false!cache-loader?cacheDirectory=./node_modules/.cache/assets-ts-img-srcset!webpack-image-resize-loader!@/assets',
  true,
  /-swiper-about\.(png|jpe?g|webp)$/i
  // 'weak'
);

export const getX2Srcset = (filePath: string | undefined) => {
  if (!filePath) return;
  try {
    filePath = filePath.replace(/^@\/assets\//, './');
    return x2SrcSets(filePath);
  } catch (e) {}
};

export const CaseStudiesSwiper = (props: { caseStudiesData: any }) => {
  // const swiper1Ref = useRef<SwiperRef>(null);
  const swiper2Ref = useRef<SwiperRef>(null);

  const [swiper1, setSwiper1] = useState<any>();
  const [swiper2, setSwiper2] = useState<any>();

  let caseStudiesData = props.caseStudiesData;
  caseStudiesData = caseStudiesData.filter((el: { slug: any }) =>
    getX2Srcset(`@/assets/images/case-studies/${el.slug}-swiper-about.webp`)
  );

  caseStudiesData = [
    ...sortBy(
      caseStudiesData.filter(
        (it: { swiperSlidesDefaultPriority: number | undefined }) =>
          it.swiperSlidesDefaultPriority !== undefined && it.swiperSlidesDefaultPriority >= 0
      ),
      ['swiperSlidesDefaultPriority']
    ),
    ...caseStudiesData.filter(
      (it: { swiperSlidesDefaultPriority: undefined }) =>
        it.swiperSlidesDefaultPriority === undefined
    ),
    ...sortBy(
      caseStudiesData.filter(
        (it: { swiperSlidesDefaultPriority: number | undefined }) =>
          it.swiperSlidesDefaultPriority !== undefined && it.swiperSlidesDefaultPriority < 0
      ),
      ['swiperSlidesDefaultPriority']
    )
  ];

  // @ts-ignore
  return (
    <section className="studies section-padding container">
      <h2 className="section-title">Case studies</h2>
      <Swiper
        className="studies__slider studies__slider-img"
        {...{
          modules: [Controller, EffectCoverflow, Lazy],
          spaceBetween: 0,
          effect: 'coverflow',
          centeredSlides: true,
          centeredSlidesBounds: true,
          breakpoints: {
            300: {
              slidesPerView: 1,
              coverflowEffect: {
                rotate: 0,
                slideShadows: false,
                depth: 0
              }
            },
            991: {
              // https://github.com/nolimits4web/swiper/issues/2542#issuecomment-386200872
              slidesPerView: 'auto',
              coverflowEffect: {
                rotate: 0,
                slideShadows: false,
                depth: 500
              }
            }
          },
          loop: true,
          loopedSlides: 3,
          slideToClickedSlide: true,
          // Disable preloading of all images
          // preloadImages: false,
          // Enable lazy loading
          lazy: {
            checkInView: true,
            loadPrevNext: true,
            enabled: true,
            loadOnTransitionStart: true,
            loadPrevNextAmount: 7
          },
          controller: { control: swiper2 },
          onSwiper: (swiper) => setSwiper1(swiper)
        }}
      >
        {caseStudiesData.map((el: { url: any; slug: any }, ind: any) => (
          <SwiperSlide key={`${el.url}|${ind}`}>
            <NoScriptPolyfill>
              <img
                className="studies__img"
                srcSet={getX2Srcset(`@/assets/images/case-studies/${el.slug}-swiper-about.webp`)}
                width={581}
                height={367}
                alt="Our case study"
              />
            </NoScriptPolyfill>
            <img
              className="swiper-lazy studies__img noScriptDisplayNone"
              /* data-src={require(
                `@/assets/images/case-studies/${el.slug}-swiper-about.webp?placeholder`
              ) }*/
              data-srcset={getX2Srcset(`@/assets/images/case-studies/${el.slug}-swiper-about.webp`)}
              width={581}
              height={367}
              alt="Our case study"
            />
            {/* // TODO Loader dont work
            <div className="swiper-lazy-preloader" />
            */}
          </SwiperSlide>
        ))}
      </Swiper>
      <NoScriptPolyfill>
        <style>
          {`
          .studies__slider.studies__slider-info .swiper-slide.swiper-slide-active {
            width: 100% !important;
          }
        `}
        </style>
      </NoScriptPolyfill>
      <Swiper
        ref={swiper2Ref}
        className="studies__slider studies__slider-info"
        {...{
          modules: [Controller],
          loop: true,
          loopedSlides: 3,
          autoHeight: true,
          controller: { control: swiper1 },
          onSwiper: setSwiper2
        }}
      >
        {caseStudiesData.map(
          (
            el: {
              url: any;
              titleNavigation: string;
              description: string;
              slug: string;
            },
            ind: any
          ) => (
            <SwiperSlide key={`${el.url}|${ind}`}>
              <div className="studies__info">
                <h3 className="studies__info-title">{el.titleNavigation}</h3>
                <p>{el.description}</p>
                <Button
                  text="View case"
                  withHoverGradient={false}
                  isColored
                  isLink
                  // @ts-ignore
                  href={`/case-studies/${el.slug}`}
                />
              </div>
            </SwiperSlide>
          )
        )}
        <button
          className="swiper-button-prev noScriptDisplayNone"
          onClick={() => swiper2Ref.current?.swiper?.slidePrev()}
          aria-label="swiper-button-prev"
        />
        <button
          className="swiper-button-next noScriptDisplayNone"
          onClick={() => swiper2Ref.current?.swiper?.slideNext()}
          aria-label="swiper-button-next"
        />
      </Swiper>
    </section>
  );
};
